import { useEffect, useMemo, useState } from 'react';

import { Button, Card, Col, Row, Steps } from 'antd';
import { OrderDetailGrid } from 'components/ArticleOrder';
import { useGetAfterCopyRightAssignmentQuery, useGetAsignmentFieldsQuery, useStartAfterCopyrightDecideProcessMutation } from 'services';
import { CircularProgress } from 'components/CircularProgress';
import { afterCopyrightStatus, openNotification } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';

import MediaField from './MediaField';
import ContentAsignment from './ContentAsignment';

const AfterCopyright = ({ offer }) => {
  const { data: assignments, isLoading } = useGetAsignmentFieldsQuery({
    type: 0,
  });
  // Steper icin gereken index degerinin statuye gore getirilmesi
  const [current, setCurrent] = useState(0);
  const currentIndex = useMemo(() => assignments?.findIndex((step) => step.status === offer.status), [assignments, offer.status]);
  const { data: afterCopyright } = useGetAfterCopyRightAssignmentQuery({
    articleOffer: offer && offer.id,
    type: 0,
  });
  const [startAfterCopyrightDecideProcess] = useStartAfterCopyrightDecideProcessMutation();

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleStartProcess = async () => {
    const body = {
      status: 2,
    };
    const { error } = await startAfterCopyrightDecideProcess({ offerID: offer && offer.id, data: body });

    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  useEffect(() => {
    setCurrent(currentIndex + 1);
  }, [currentIndex, offer.status]);

  const afterCopyrightSteps = [
    {
      id: crypto.randomUUID(),
      title: afterCopyrightStatus[27],
      style: { cursor: 'pointer' },
    },
    ...(assignments?.map((step, index) => ({
      id: index,
      title: afterCopyrightStatus[step.status],
      disabled: step.status > offer.status || step.status === 27,
    })) || []),
  ];

  if (isLoading) return <CircularProgress />;
  return (
    <div className="gx-pb-4">
      <Steps
        progressDot
        onChange={(current) => setCurrent(current)}
        current={current}
        labelPlacement="vertical"
        items={afterCopyrightSteps.sort((a, b) => a.status - b.status)}
      />
      <Card className="gx-p-3">
        <OrderDetailGrid offer={offer} />
        <MediaField
          description={afterCopyright?.[Number(current) - 1]?.description}
          offerID={offer && offer.id}
          onAssignStep={offer && offer.status === 27}
          status={offer && offer.status}
        />
        {/* sadece madde atama asamasinda goruntulenecek bir komponent */}
        {current === 0 && (
          <>
            <ContentAsignment
              status={offer && offer.status}
              offerURI={offer && offer['@id']}
              assignments={assignments}
              afterCopyright={afterCopyright}
            />
            <Row gutter={24}>
              <Col
                sm={24}
                md={12}
              >
                <Button block>Geri</Button>
              </Col>
              <Col
                sm={24}
                md={12}
              >
                <Button
                  onClick={handleStartProcess}
                  block
                >
                  Telif Sonrası Sürecini Başlat{' '}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Card>
    </div>
  );
};

export default AfterCopyright;
