import apiInstance from './api';

const afterCopyrightApi = apiInstance.injectEndpoints({
  endpoints: (build) => ({
    getAfterCopyRightAssignment: build.query({
      query: ({ articleOffer, type }) => ({
        url: '/assignments',
        method: 'GET',
        params: {
          articleOffer,
          type,
        },
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['AfterCopyRightAssignment'],
    }),
    getAsignmentFields: build.query({
      query: ({ type }) => ({
        url: '/assignment_fields',
        method: 'GET',
        params: {
          type,
        },
      }),
      transformResponse: (response) => response['hydra:member'],
      providesTags: ['AfterCopyRightAssignmentFields'],
    }),
    startAfterCopyrightDecideProcess: build.mutation({
      query: ({ offerID, data }) => ({
        url: `/article_offers/decision/eu/${offerID} `,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment', 'ArticleOfferDetail'],
    }),
    makeAssigmentDecision: build.mutation({
      query: ({ data, offerID }) => ({
        url: `/assignments/${offerID}/decide`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment', 'ArticleOfferDetail'],
    }),
    postAssignment: build.mutation({
      query: ({ data }) => ({
        url: '/assignments',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment'],
    }),
    updateAssigment: build.mutation({
      query: ({ data, assigmentID }) => ({
        url: `/assignments/${assigmentID}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['AfterCopyRightAssignment'],
    }),
  }),
  overrideExisting: true,
});
export default afterCopyrightApi;
