import { useMemo } from 'react';

import useSWR from 'swr';
const useAllOffersVersions = ({ committeeId = '', page = 1, subMenus = '', pageSize = 20, orderType = '', filtered = '' }) => {
  const arrangedURL = useMemo(() => {
    // taslak halindeki ve görüş istenen maddelerin listelenmesi için versiyondan değil
    // orjinal istekten veri çekilmesi gerektiği için bu tarz bir kontrol ekledi
    if (subMenus.includes('&isDraft=true') || subMenus.includes('&opinionCommittee')) {
      return 'article_offers';
    }
    return 'article_offer_versions';
  }, [subMenus]);

  const deletePageSizeOnFiltered = filtered.includes('pageSize') ? filtered.replace(/&pageSize=\d+/, '') : filtered;
  const deletePageOnFiltered = filtered.includes('page') ? deletePageSizeOnFiltered.replace(/&page=\d+/, '') : deletePageSizeOnFiltered;
  const searhcParams = useMemo(
    () =>
      new URLSearchParams({
        ...(committeeId && { committee: committeeId }),
        ...(page && { page }),
        ...(pageSize && { itemsPerPage: pageSize }),
        ...(orderType.type && { [`order[${orderType.type}]`]: orderType.order }),
      }),
    [committeeId, page, pageSize, orderType]
  );
  const url = useMemo(() => {
    return `/${arrangedURL}?${searhcParams}${deletePageOnFiltered}${subMenus !== '' ? subMenus : ''}`;
  }, [searhcParams, arrangedURL, deletePageOnFiltered, subMenus]);

  const { data, error, isValidating, mutate } = useSWR(url);
  return {
    url,
    mutate,
    offers: data,
    isLoading: !error && !data,
    fethcing: isValidating,
    isError: error,
  };
};

export default useAllOffersVersions;
