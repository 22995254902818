import { useEffect } from 'react';

import { usePostAssignmentMutation, useUpdateAssigmentMutation } from 'services';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';
import { openNotification } from 'utils';

import AsignItem from './AsignItem';

const ContentAsignment = ({ offerURI, assignments, afterCopyright }) => {
  const [postAssignment] = usePostAssignmentMutation();
  const [updateAssigment] = useUpdateAssigmentMutation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const handleIsEditable = (status) => {
    return afterCopyright?.some((item) => item.field.status === status);
  };

  const handleNewAssignment = async (value, fieldURI) => {
    const { error } = await postAssignment({
      data: {
        articleOffer: offerURI,
        field: fieldURI,
        user: value,
      },
    });

    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };
  const handleUpdateAssignment = async (value, status) => {
    const { error } = await updateAssigment({
      data: {
        user: value,
      },
      assigmentID: form.getFieldValue(`assignmentID_${status}`),
    });
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  // form a ilgili degerlerin set edilmesini saglayasn kisim
  useEffect(() => {
    const formValues = afterCopyright?.reduce((acc, item) => {
      acc[item.field.status] = item.user && item.user['@id'];
      acc[`assignmentID_${item.field.status}`] = item.id;

      return acc;
    }, {});
    form.setFieldsValue({
      ...formValues,
    });
  }, [afterCopyright, form]);
  return (
    <div>
      <h3>Madde Atama</h3>
      <Form form={form}>
        {assignments.map((step) => (
          <AsignItem
            key={step.id}
            field={step['@id']}
            status={step.status}
            role={step.role}
            handleSelect={(value, fieldURI, status) =>
              handleIsEditable(step.status) ? handleUpdateAssignment(value, status) : handleNewAssignment(value, fieldURI)
            }
            handleIsEditable={handleIsEditable}
          />
        ))}
      </Form>
    </div>
  );
};

export default ContentAsignment;
