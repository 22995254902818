import { useState } from 'react';

import { Card, Col, Collapse, Divider, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import StartProcessStepActions from 'components/ArticleOrder/status/StartProcessStepActions.jsx';
import SendItemSteps from 'components/ArticleOrder/status/SendItemSteps';
import {
  useGetArticleChainsQuery,
  useGetArticleOfferProcessQuery,
  useGetAskedCommitteesQuery,
  useGetCurrentUserQuery,
  useGetDecidedCommitteesQuery,
} from 'services';
import { CircularProgress } from 'components/CircularProgress';
import { useUserHasPermission } from 'hooks';
import StatusBar from 'components/StatusBar';
import { article_order_status } from 'constant/static';
import { useNavigate } from 'react-router-dom';
import RevertAnswerItem from 'components/ArticleOffer/offer/ArticleStatus/RevertAnswerItem';
import dayjs from 'dayjs';

import { OrderDetailGrid } from './OrderDetailGrid';
import { OrderDetailDescriptions } from './OrderDetailDescriptions';
import { OrderDetailAddendum } from './OrderDetailAddendum';
import { OrderAgreement } from './OrderAgreement';
import { ArticleOrderStatus } from './ArticleOrderStatus';
import OrderInterviewHistory from './OrderInterviewHistory';
import { AuthorDecision, StartOrderProcess } from './status';
import ArticleOrderMedia from './ArticleOrderMedia';
import SendToCommitteeActions from './status/SendToCommitteeActions';
import CommiteeDecisionActions from './status/CommiteeDecisionActions';
import { ArticleInterviewNote } from './ArticleInterviewNote/ArticleInterviewNote';
import GiveCommitteeOpinionAction from './status/GiveCommitteeOpinionAction/GiveCommitteeOpinionAction';
import ArticleOrderAdditionalNote from './ArticleOrderAdditionalNote';
import SavingContractAction from './SavingContractAction/SavingContract';
import ArticleOfferVersionItem from './ArticleOfferVersionItem';
import ActivityHistory from './ActivityHistory';
import ComitteeComments from './ComitteeComments';

const { Panel } = Collapse;

export const ArticleOrderDetail = ({ offer }) => {
  const { data: current_user } = useGetCurrentUserQuery();
  const { handlePermission, handleUserIsOnCommittee, authorHasPermission } = useUserHasPermission();
  const { data: processTrack, isLoading: processTrackLoading } = useGetArticleOfferProcessQuery(offer.id);
  const { data, isLoading, isError } = useGetDecidedCommitteesQuery(offer && offer.id, {
    skip: (offer && !offer.id) || !offer?.ihControlStatus,
  });
  const navigate = useNavigate();
  const {
    data: askedCommitees,
    isLoading: askedCommiteesLoading,
    isError: askedCommiteesError,
  } = useGetAskedCommitteesQuery(offer && offer.id, {
    skip: (offer && !offer.id) || !offer?.ihControlStatus,
  });

  const { data: articleChains, isLoading: articleChainLoading } = useGetArticleChainsQuery(offer && offer.id);

  const decidedCommittee = data?.decisions.filter((item) => item.isDecided === true);
  // TODO: need refactoring
  const [modalProps, setModalProps] = useState({
    visible: false,
    data: null,
  });

  const handleModalProps = (visible, data) => {
    setModalProps({ visible, data });
  };

  /**
   * madde versiyonları arası navigasyon işlemi burada kontrol ediliyor
   */
  const handleNavigate = (id) => {
    navigate(`/madde-detay/${id}`);
  };

  const authorNote = processTrack?.filter((item) => ['approved', 'rejected'].includes(item.opinion) && item.opinionDescription !== null);
  const isUserJustAuthor = current_user.isAuthor && current_user.memberships.length === 0;

  /**
   * Sistemdeki yazarın madde yazarı olup olmadığı kontrolü
   */
  const authorPermission = authorHasPermission(offer && offer.authors);
  if (isLoading || askedCommiteesLoading || articleChainLoading) return <CircularProgress />;

  /**
   * Görüş istenen heyetlerin id leri
   */
  const decidedCommitteeID = data?.decisions.map((item) => {
    return item.committee.id;
  });
  const isUserDecide = handlePermission(decidedCommitteeID);
  const isAuthorDecideAndOnNextStep = processTrack?.some(
    (item) => ['rejected', 'notAnswered'].includes(item.opinion) && item.author.user.id === current_user.id
  );
  // Madde iade durumunu burada kontrol ediliyor
  const isItemRevert = offer.status === article_order_status['Yazar-Gündemde'] && offer.ihControlStatus;
  return (
    <>
      {(!isUserJustAuthor || current_user.isAdmin || current_user.isOrderUnit || current_user.isComittee || current_user.isDAH || current_user.isAYK) && (
        <>
          {articleChains && articleChains.parent && (
            <ArticleOfferVersionItem
              label="Bu Maddenin Önceki Versiyonu:"
              btnText="Önceki Versiyon"
              onClick={() => handleNavigate(articleChains.parent)}
            />
          )}
          {offer?.status === article_order_status['IH-Madde Kontrol Yeniden Telif'] && articleChains.child && (
            <ArticleOfferVersionItem
              label="Bu Maddenin Sonraki Versiyonu:"
              btnText="Sonraki Versiyon"
              onClick={() => handleNavigate(articleChains.child)}
            />
          )}
          {offer?.status === article_order_status['IH-Madde Kontrol Yeniden Telif'] && articleChains && articleChains.child !== articleChains.latestChild && (
            <ArticleOfferVersionItem
              label="Maddenin Güncel Versiyonu:"
              btnText="Son Versiyon"
              onClick={() => handleNavigate(articleChains.latestChild)}
            />
          )}
        </>
      )}
      {(authorPermission || current_user?.isOrderUnit || current_user?.isAdmin) &&
        [article_order_status['Yazar-Gündemde'], article_order_status['Yazar-Onay'], article_order_status['SB-Gündemde']].includes(offer?.status) &&
        !processTrackLoading && (
          <ArticleOrderStatus
            processData={processTrack}
            offer={offer}
          />
        )}
      {/**
       * Yazarın Maddeyi Onaylarken/Reddederken ilettiği not
       */}
      {authorNote?.map((item) => (
        <Card
          key={item.id}
          className={`${item.author.user.id === current_user.id || current_user.isOrderUnit || current_user.isAdmin ? 'gx-mb-3' : 'gx-display-none '}`}
        >
          <Collapse className="commitment-agreement">
            <Panel
              key={item.id}
              header={<h2>{`Yazarın ${item.opinion === 'approved' ? 'onay' : 'reddetme'} notu (${item.author.user.fullName})`}</h2>}
              extra={<div className="gx-d-flex gx-align-items-center">{dayjs(item.opinionDate).format('DD.MM.YYYY HH:mm')}</div>}
            >
              {item.opinionDescription}
            </Panel>
          </Collapse>
        </Card>
      ))}
      {(current_user?.isAuthor || current_user?.isOrderUnit || current_user?.isAdmin) &&
        [article_order_status['Yazar-Onay'], article_order_status['Yazar-Gündemde']].includes(offer?.status) && (
          <ArticleOrderAdditionalNote id={offer && offer.id} />
        )}

      {/* Madde Sipariş birimine geçince statü 19 oluyor sonraki bütün süreçlerde gözükmesi durumu için bu tarz bir kontrol yapıldı*/}
      {(offer?.status >= article_order_status['IH - Taslak'] ||
        [article_order_status.Onaylandı, article_order_status.Reddedildi].includes(offer && offer.status)) &&
        (current_user?.isOrderUnit || current_user?.isAdmin) && (
          <Card className="gx-p-3">
            <OrderInterviewHistory offerID={offer && offer.id} />
          </Card>
        )}

      {/* Madde Sipariş birimine geçince statü 19 oluyor  sonraki bütün süreçlerde gözükmesi durumu için bu tarz bir kontrol yapıldı*/}
      {offer?.status >= article_order_status['IH - Taslak'] && (current_user.isOrderUnit || current_user.isAdmin) && (
        <ArticleInterviewNote id={offer && offer['@id']} />
      )}

      <h2 className="gx-h2-lg">
        <FormattedMessage id="article.steps.ArticleOffer" />
      </h2>
      <Card className="gx-p-3">
        <StatusBar status={offer && offer.status} />
        <OrderDetailGrid offer={offer} />
        <OrderDetailDescriptions offer={offer} />
        <OrderDetailAddendum offer={offer} />
        {(current_user.isOrderUnit || current_user.isAdmin || current_user.isComittee) &&
          (offer.status >= article_order_status['SB-Madde Kontrol'] || article_order_status.Onaylandı || article_order_status.Reddedildi) && (
            <ActivityHistory id={offer && offer.id} />
          )}
        {offer?.revertReasons?.committee_control?.length > 0 && (
          <Card className="gx-p-3">
            <Collapse className="commitment-agreement decision">
              <Panel header={<h2>{offer.ihControlStatus === 3 ? 'İade' : 'Ret'} Gerekçesi</h2>}>
                <ul>
                  {offer.revertReasons.committee_control.map((item) => (
                    <RevertAnswerItem
                      key={item.id}
                      title={item.revertReason.title}
                      answer={item.answer}
                    />
                  ))}
                </ul>
              </Panel>
            </Collapse>
          </Card>
        )}
        {/**
         * Yazarın gönderdiği içerikler
         */}
        {(current_user.isOrderUnit ||
          current_user.isAdmin ||
          authorPermission ||
          current_user.isDAH ||
          current_user.isAYK ||
          handlePermission(offer && offer.ownerCommittee.id)) &&
          ([
            article_order_status.Onaylandı,
            article_order_status.Reddedildi,
            article_order_status['SB-Madde Kontrol'],
            article_order_status['IH-Madde Kontrol'],
            article_order_status['IH-Madde Kontrol Görüş İstedi'],
            article_order_status['IH-Madde Kontrol Yeniden Telif'],
          ].includes(offer && offer.status) ||
            isItemRevert) && (
            <ArticleOrderMedia
              isContentSend={true}
              modalProps={modalProps}
              handleModalProps={handleModalProps}
              offerID={offer.id}
            />
          )}
        <OrderAgreement offer={offer} />
        {/**
         * Heyet Görüşleri
         */}
        {(current_user.isOrderUnit ||
          current_user.isAdmin ||
          current_user.isDAH ||
          current_user.isAYK ||
          isUserDecide ||
          handleUserIsOnCommittee(offer && offer.ownerCommittee.id)) &&
          decidedCommittee?.length > 0 && (
            <>
              <Divider />
              <ComitteeComments data={decidedCommittee} />
            </>
          )}
        {offer?.ihControlStatus && (offer?.ihControlDescription || offer?.ihControlMediaOfferDescription) && (
          <>
            <h4>İlim Heyeti Açıklamaları</h4>
            <Collapse className="gx-mb-5">
              {offer?.ihControlMediaOfferDescription && (
                <Panel
                  header="Heyet Medya Kontrol Açıklaması"
                  key="ihControlMediaOfferDescription"
                >
                  {offer?.ihControlMediaOfferDescription}
                </Panel>
              )}
              {offer?.ihControlDescription && (
                <Panel
                  header="Heyet Kontrol Açıklaması"
                  key="ihControl"
                >
                  {offer?.ihControlDescription}
                </Panel>
              )}
            </Collapse>
          </>
        )}
        {[article_order_status['Yazar-Onay'], article_order_status['Yazar-Gündemde']].includes(offer?.status) &&
          (current_user.isOrderUnit || current_user.isAdmin) && (
            <StartProcessStepActions
              disabled
              title={offer && offer.title}
              authors={offer && offer.authors}
              id={offer && offer.id}
              orderDate={offer && offer.ouDate?.orderDate}
              deliveryDate={offer && offer.ouDate?.deliveryDate}
            />
          )}
        {(current_user?.isAdmin || current_user?.isOrderUnit) && offer?.status === article_order_status['SB-Gündemde'] && (
          <Row gutter={24}>
            {/* <Col
              md={12}
              sm={24}
            >
              <EditArticleOrderDate
                id={offer && offer.id}
                orderDate={offer && offer.ouDate?.orderDate}
                deliveryDate={offer && offer.ouDate?.deliveryDate}
                title={offer.title || ''}
                authors={offer.authors.map((item) => item.author)}
              />
            </Col> */}
            <Col
              md={24}
              sm={24}
              className="gx-w-100"
            >
              <StartOrderProcess offer={offer} />
            </Col>
          </Row>
        )}
        {authorPermission && offer?.status === article_order_status['Yazar-Onay'] && !isAuthorDecideAndOnNextStep && <AuthorDecision offer={offer} />}
        {authorPermission && offer?.status === article_order_status['Yazar-Gündemde'] && (
          <SendItemSteps
            offerId={offer?.id}
            title={offer?.title}
            isAuthorPage={true}
          />
        )}
        {[
          article_order_status['Telif Sonrasi'],
          article_order_status['IH-Madde Kontrol Yeniden Telif'],
          article_order_status.Onaylandı,
          article_order_status.Reddedildi,
        ].includes(offer && offer.status) &&
          (current_user.isAdmin || current_user.isOrderUnit) && (
            <SavingContractAction
              status={offer && offer.status}
              articleLength={offer && offer.articleLength}
              offerID={offer && offer.id}
              authors={offer && offer.authors}
              articleLengthCustom={offer && offer.articleLengthCustom}
            />
          )}
        {(current_user.isAdmin || current_user.isOrderUnit) && offer?.status === 23 && (
          <SendToCommitteeActions
            ihControlStatus={offer && offer.ihControlStatus}
            offerId={offer && offer.id}
            step={offer && offer.step}
          />
        )}
        {(handlePermission(offer && offer.ownerCommittee.id) || current_user.isAdmin) && offer?.status === 24 && (
          <CommiteeDecisionActions
            offerId={offer && offer.id}
            commiteeID={offer && offer.ownerCommittee && offer.ownerCommittee.id}
            step={offer && offer.step}
          />
        )}
        {offer?.status === article_order_status['IH-Madde Kontrol Görüş İstedi'] &&
        (!isError || !askedCommiteesError) &&
        isUserDecide &&
        askedCommitees?.decisions?.length > 1 ? (
          <GiveCommitteeOpinionAction committes={askedCommitees && askedCommitees.decisions} />
        ) : (
          offer?.status === article_order_status['IH-Madde Kontrol Görüş İstedi'] &&
          (!isError || !askedCommiteesError) &&
          isUserDecide &&
          askedCommitees?.decisions?.length === 1 &&
          !askedCommitees?.decisions?.[0]?.isDecided && <GiveCommitteeOpinionAction committes={askedCommitees && askedCommitees.decisions} />
        )}
      </Card>
    </>
  );
};
